$(function () {
  $(window).on('load scroll', function () {
    const windowHeight = $(window).height();
    const scroll = $(window).scrollTop();

    $('.content').each(function () {
      const targetPosition = $(this).offset().top;
      if (scroll > targetPosition - windowHeight + 200) {
        $(this).addClass("content-is-fadein");
      }
    });
  });
});
